<template>
  <div>
    <!--  -->
    <div id="RemoteVideo" style="z-index: -1; top:-200vh;">
    </div>
    <canvas id="userDefaultCanvas" width="64px" height="64px"></canvas>
  </div>
</template>
<script>
// import VERTC from '@volcengine/rtc';
// import { StreamIndex,MediaType } from '@volcengine/rtc';
import axios from 'axios';
import TRTC from 'trtc-sdk-v5';
import { CDNStreaming, PublishMode } from 'trtc-sdk-v5/plugins/cdn-streaming';
export default {
  data() {
    return {
      engine: null,  //火山rtc实例
      token: null,
      roomId: null,
      teacherId: null,
      remoteId: null,
      defaultStream: null, //默认流
      trtc:null,
    }
  },
  props: ['audioStream', 'LocalVideoStream', 'roomId'],
  methods: {
    async leaveRoom() {
      await this.trtc.exitRoom();
      // this.trtc.destroy()
      this.trtc = null;
      console.log('已经退出房间');
    },
    async initRTC() {
      var trtc;

      this.trtc = TRTC.create({ plugins: [CDNStreaming] });
      trtc = this.trtc;
      // 监听远端视频流
      trtc.on(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, async ({ userId, streamType }) => {
        if (streamType === TRTC.TYPE.STREAM_TYPE_MAIN) {
          console.log('主路视频流');
          const player = 'RemoteVideo';
          await trtc.startRemoteVideo({
            userId, streamType, view: 'RemoteVideo', option: {
              fillMode: 'contain',
            }
          });
          console.log(trtc.getVideoTrack(userId, streamType).getSettings(), '---getSettings--')
          setTimeout(() => {
            this.$emit('RemotePublishStreamVideo');
          }, 200);
        } else {
          console.log('辅路视频流');
        }
      });
      // 监听远端视频流离开
      trtc.on(TRTC.EVENT.REMOTE_VIDEO_UNAVAILABLE, ({ userId, streamType }) => {
        console.log('离开房间');
        this.$emit('closeLianmai');
        if (streamType == TRTC.TYPE.STREAM_TYPE_MAIN) {

        } else {
          this.roomShareStatus = true;
          this.remoteShareStatus = true;
        }
      })
      // 监听远端音频流
      trtc.on(TRTC.EVENT.REMOTE_AUDIO_AVAILABLE, event => {
        const remoteuserId = event.userId;
        console.log(remoteuserId);
        console.log('远端音频流加入');
        setTimeout(async () => {
          let remoteAudio = await trtc.getAudioTrack({ userId: remoteuserId }); // 获取远端 audioTrack
          console.log(remoteAudio);
          this.$emit('RemotePublishStreamAudio', remoteAudio);
        }, 1000);
      });
      // 远端音频流离开
      trtc.on(TRTC.EVENT.REMOTE_AUDIO_UNAVAILABLE, event => {
        const userId = event.userId;
        console.log('远端音频流离开');
      });
      const sdkAppId = 1600045359;
      // const sdkAppId = 1600063814;
      const userId = 'test01';
      const userSig = 'eJwtzEELgjAYxvHvsmuh0-mqEzxEYJB1yUEoXpTNeolMdMkg*u6Zenx*f3g*RJwya1Q9iYhrUbKdN0rVamxwZq0GTZ21DPJRdR1KEjk*pdQDBnwpynTYq8kBwJ3SohqffwsY40HIwV9f8DYd8*oo3vfClHZpp*1OHAaR0jGr6*RlZM68835zDaGAJrmEeUy*P6HaMYQ_';
      // const userSig = 'eJwtzF0LgjAYhuH-slND3qlbIXQywegLyrRzdavemrF0RBT995Z6*Fw3PB*Sbw7*U7UkJoEPZNJvlOpu8YQ9W9VZoGPp5K00BiWJKQcAHs5oNBT1Mtgq54yxwKVBLTZ-m4YhpRABH1-w7I6PJaZ6b9NMcFHV3mN7FV7xvhQrWTWQLXW*2NWJXOso6*bk*wM6JDFk'
      const roomId = this.roomId;
      await trtc.enterRoom({ strRoomId: this.roomId, sdkAppId, userId, userSig });
      const config = {
        view: null, // 在 DOM 中的 elementId 为 localVideo 的标签上预览视频。
        publish: true,
        option: {
          videoTrack: this.LocalVideoStream.getVideoTracks()[0],
          fillMode: 'contain',
          profile: '720p'
        }
      }
      await trtc.startLocalVideo(config);
      console.log('加入房间成功');

    },
    async getToken() {
      // this.roomId = store.get('roomId')
      // // await this.getSign()
      // this.teacherId = store.get('identifier');
      // axios.get(`https://tiku.ixunke.com/api/v1/rtcroom/token?roomID=${this.roomId}&userID=${this.teacherId}`).then(res=>{
      //   console.log(res.data.data.token);
      //   this.token = res.data.data.token;
      //   // this.token = '00166978ec5770dbb01a48f122cMgD/oJkvrMSxZiwWs2YBADEBADEFAAAALBazZgEALBazZgIALBazZgMALBazZgQAAAAAACAA5qI5ani34CNKMHOaylyBFUTFr+RuhJTPwbtIaWxgzMY=';
      //   this.joinRoom()
      // }).catch(error=>{
      //   this.$message.error('获取连麦token出错了，请联系技术支持')
      // })
      // 
    },
    async joinRoom() {

      console.log('开始进入房间');


    },
    async createLocalStream() {

    },
    // 接收到远端进入房间消息
    async handleUserJoin(user) {
      console.log('onUserJoined');
      console.log(user);
    },
    async handleUserLeave(e) {
      // console.log('handleUserLeave', e);
      // console.log(e.userInfo.userId);
      let id = e.userInfo.userId
      if (id == this.remoteId) {
        this.$emit('closeLianmai')
      }
    },
    async onUserLeave(stream) {
      console.log(stream);
      let mediaType = stream.mediaType;

    },
    async handleUserUnpublishStream(stream) {
      const { userId, mediaType } = stream;
      console.log('handleUserUnpublishStream');
      console.log(userId, mediaType);
      if (mediaType == 2) {
        this.$emit('handleUserUnpublishVideo');
        const player = document.getElementById('RemoteVideo');
        player.innerHTML = '';
      }
    },
    async handleUserPublishStream(stream) {
      console.log('新增了一个远端流', stream);
      const { userId, mediaType } = stream;
      this.remoteId = userId;
      const remoteUserId = userId;
      // console.log(userId, mediaType);

      if (mediaType) {
        // console.log(mediaType);
        const player = document.getElementById('RemoteVideo');
        if (player) {
          if (mediaType == 2 || mediaType == 3) {
            await this.engine.setRemoteVideoPlayer(0, {
              userId: remoteUserId,
              renderDom: player,
              playerId: 'RemoteVideoDom',
              contentHint: 'motion',
              VideoRenderMode: 0,
            });
            this.$emit('RemotePublishStreamVideo');
          }
          let video = player.querySelector('video');
          if (mediaType == 1 || mediaType == 3) {
            setTimeout(async () => {
              let remoteAudio = this.engine.getRemoteStreamTrack(remoteUserId, 0, 'audio');
              // console.log(remoteAudio);
              // console.log('---------remoteAudio--------');
              this.$emit('RemotePublishStreamAudio', remoteAudio);
            }, 1000);
          }
        }

      }
    }
  },
  async mounted() {
    // this.joinRoom()
    console.log(TRTC);
  },
  beforeDestroy() {
    this.leaveRoom()
  }
}
</script>
<style lang="scss" scoped>
#RemoteVideo {
  width: 240px;
  height: 270px;
  position: fixed;
}

::v-deep #RemoteVideo video {
  object-fit: contain;
}

#userDefaultCanvas {
  position: absolute;
  z-index: -1;
  top: -100vh;
}
</style>