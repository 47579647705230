<style lang="scss" scoped>
::v-deep .chat-room-loading {
  .ant-spin-container {
    width: 100%;
    height: 100%;
  }
}

.ixunke-tab-btn-box {
  display: flex;
  // position: absolute;
  width: 100%;
  // height: 41px;
  top: 0;
  left: 0;
  z-index: 999;
  border-bottom: 1px solid #4a4a4a;
  background: #30323d;

  .chuangkou {
    width: 30px;
    height: 30px;
    padding-top: 10px;
    margin-left: 4px;

    img {
      width: 24px;
      height: 24px
    }
  }
}

.ixunke-tab-btn {
  flex: 1;
  height: 40px;
  text-align: center;
  background: #3e4149;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  color: #fff;
  cursor: pointer;
}

.ixunke-tab-btn-active {
  // background: #3e4149;
  background: #2e3037;
  color: #fff;
}

.ixunke-tab-content-box {
  position: relative;
  width: 100%;
  height: 91vh;
  background: #30323d;
  padding-top: 52px;
  padding-bottom: 160px;
  z-index: 99;
  overflow-y: scroll;
}


.ixunke-tab-content-box::-webkit-scrollbar {
  background: #30323d;
}

::v-deep .vertica {
  background: transparent !important;
  padding-top: 0 !important;

  .ixunke-chat-item-info-right,
  .ixunke-chat-item-info {
    background: rgba(0, 0, 0, .2) !important;
  }

  .ixunke-chat-send-btn {
    background: #745a4a;
  }

  .ixunke-chat-input {
    border: 1px solid #544139;
  }

}

::v-deep.vertica::-webkit-scrollbar {
  background: transparent !important;
}
</style>
<template>
  <div style="position: relative; width: 100%; height: 100%;">
    <a-spin style="width: 100%; height: 100%;" :spinning="loading" :tip="loadText" size="large"
      class="chat-room-loading">
      <a-empty style="padding-top: 60%;color: #fff;" v-if="!SDKAppId" description="暂未开通聊天室" />
      <template v-else>
        <div class="ixunke-tab-btn-box" v-if="!liveDetail?.isVertical">
          <div class="ixunke-tab-btn" :class="tabBtn == 'discuss' ? 'ixunke-tab-btn-active' : ''"
            @click="tabBtnClickEvent('discuss')">
            互动消息
          </div>
          <!-- <div class="ixunke-tab-btn" style="border-right: 1px solid #4a4a4a;"
            :class="tabBtn == 'questions' ? 'ixunke-tab-btn-active' : ''" @click="tabBtnClickEvent('questions')">
            问题列表
          </div> -->
          <div class="chuangkou" v-if="f" @click="showAudienceWin">
            <img src="./img/chuangkou.png" alt="悬浮窗" title="悬浮窗">
          </div>
        </div>
        <div class="ixunke-tab-content-box" :class="liveDetail?.isVertical == 1 ? 'vertica' : ''" style="">
          <template v-if="tabBtn == 'discuss'">
            <messageList :isMaster="true" :userId="userID" :list="messageArr" @messageAction="messageAction"
              @sendMessageEvent="createMessageEvent" ref="messageList" />
            <messageSend :liveDetail="liveDetail" :f="f" @sendQuestionnaire="createMessageEvent" :quoteObj="quoteObj"
              @clearQuoteObj="clearQuoteObj" @sendMessageEvent="createMessageEvent" />
          </template>
          <template v-if="tabBtn == 'questions'">
            <messageList ref="messageList" :isMaster="true" :userId="userID" :list="messageArr" :isQuestion="true"
              @messageAction="messageAction" />
          </template>
        </div>
      </template>
    </a-spin>
  </div>
</template>
<script>
// import moment from "moment";
import { ixunkeAxios } from "@/assets/js/axios.js";
import axios from 'axios';
const { ipcRenderer } = window.require("electron");

// import TIM from "tim-js-sdk";
// import COS from "cos-js-sdk-v5";
import messageList from "./message-list.vue";
import messageSend from "./message-send.vue";
import XKIM from '@/assets/js/xk-im.js'
var MYIM;
const Store = window.require("electron-store");
const store = new Store();
export default {
  name: "liveChatRoom",
  components: {
    messageList,
    messageSend,
  },
  props: {
    liveDetail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    f: {
      type: Boolean,
      default: true,
    }
  },
  inject: ['lianmai'],
  data() {
    return {
      liveId: this.$route.query.id,
      liveType: this.$route.query.type,
      liveTitle: this.$route.query.title,
      //加载动画
      loading: false,
      loadText: "加载中...",
      //聊天室
      tim: null,
      SDKState: false,
      SDKAppId: 0,
      userID: "",
      userSig: "",
      chatroomId: 0,
      tabBtn: "discuss",
      //消息
      allMessageSeq: 0,
      isAllMessage: false,
      sendMessageErrorCount: 0,
      messageArr: [],
      quoteObj: null, //正在引用的消息
      avatarUrl: "", //头像链接
      userInfo: "", //个人信息
      nickname: "", //name
      roomId: "",
      loginlock: false, //登录锁
      allMessageList: [],
    };
  },

  // watch: {
  // liveDetail: {
  //   async handler(newval, oldval) {
  //     console.log('================================');
  //     console.log(newval);
  //     this.imType = newval?.imType || 'xk';
  //     // console.log(this.imType);
  //     // chatroom 腾讯聊天室
  //     // xunkeImChatroom  讯课聊天室
  //     // this.imType = 'xk'
  //     // console.log(this.imType);
  //     if (this.imType == 'xk') {
  //       if (newval.xunkeImChatroom && newval.xunkeImChatroom.chatroomId) {
  //         this.SDKAppId = newval.xunkeImChatroom.SDKAppId;
  //         this.userID = newval.xunkeImChatroom.identifier;
  //         this.userSig = newval.xunkeImChatroom.userSig;
  //         this.chatroomId = newval.xunkeImChatroom.chatroomId;
  //         // console.log(newval,'--this.xunkeImChatroom------');
  //         this.roomId = newval?.xunkeImChatroom.chatroomId || newval?.chatroom.chatroomId;

  //       }
  //     } else {
  //       if (newval.chatroom && newval.chatroom.chatroomId) {
  //         // console.log( newval.chatroom,'---------------------');
  //         this.SDKAppId = newval.chatroom.SDKAppId;
  //         this.userID = newval.chatroom.identifier;
  //         this.userSig = newval.chatroom.userSig;
  //         this.chatroomId = newval.chatroom.chatroomId;
  //         this.roomId = "";
  //         //聊天室消息---获取聊天室消息  isAllMessage: false,  allMessageSeq: 0,

  //       }
  //     }
  //     //聊天室---初始化
  //     // this.initTimEvent();
  //     //聊天室消息---获取聊天室消息  isAllMessage: false,  allMessageSeq: 0,
  //     // this.getMessageList();

  //     // await this.getAllMessageList()



  //   },
  //   //   immediate: true,
  //   //   deep: true,

  //   // },
  //   roomManagers() {
  //     return [];
  //   },
  //   modal: function (val) {
  //     this.$emit("update:questionModal", val);
  //   },
  // },
  created() {
    this.host = store.get("host");
    try {
      const avatar = store.get("userInfo").avatar;
      this.avatarUrl = this.host + avatar;
      this.userInfo = store.get("userInfo");
      this.nickname = this.userInfo.nickname;

    } catch (error) {

    }


    // console.log(this.liveDetail,'---liveDetail---');

    var newval = this.liveDetail
    this.imType = newval?.imType || 'xk';
    // console.log(this.imType,'------this.imType ------');
    // console.log(newval);
    if (this.imType == 'xk') {
      if (newval.xunkeImChatroom && newval.xunkeImChatroom.chatroomId) {
        console.log(newval.xunkeImChatroom.SDKAppId);
        console.log('-------------------------------------');
        this.SDKAppId = newval.xunkeImChatroom.SDKAppId;
        this.userID = newval.xunkeImChatroom.identifier;
        this.userSig = newval.xunkeImChatroom.userSig;
        this.chatroomId = newval.xunkeImChatroom.chatroomId;
        // console.log(newval,'--this.xunkeImChatroom------');
        this.roomId = newval?.xunkeImChatroom.chatroomId || newval?.chatroom.chatroomId;

      }
    } else {
      if (newval.chatroom && newval.chatroom.chatroomId) {
        // console.log( newval.chatroom,'---------------------');
        this.SDKAppId = newval.chatroom.SDKAppId;
        this.userID = newval.chatroom.identifier;
        this.userSig = newval.chatroom.userSig;
        this.chatroomId = newval.chatroom.chatroomId;
        this.roomId = "";
        //聊天室消息---获取聊天室消息  isAllMessage: false,  allMessageSeq: 0,

      }
    }
    //聊天室---初始化
    this.initTimEvent();
    // console.log(this.f,'-----f-----');



  },
  watch: {
    liveDetail: {
      async handler(newval, oldval) {
        this.imType = newval?.imType || 'xk';
        if (this.imType == 'xk') {
          if (newval.xunkeImChatroom && newval.xunkeImChatroom.chatroomId) {
            this.SDKAppId = newval.xunkeImChatroom.SDKAppId;
            this.userID = newval.xunkeImChatroom.identifier;
            this.userSig = newval.xunkeImChatroom.userSig;
            this.chatroomId = newval.xunkeImChatroom.chatroomId;
            // console.log(newval,'--this.xunkeImChatroom------');
            this.roomId = newval?.xunkeImChatroom.chatroomId || newval?.chatroom.chatroomId;

          }
        } else {
          if (newval.chatroom && newval.chatroom.chatroomId) {
            // console.log( newval.chatroom,'---------------------');
            this.SDKAppId = newval.chatroom.SDKAppId;
            this.userID = newval.chatroom.identifier;
            this.userSig = newval.chatroom.userSig;
            this.chatroomId = newval.chatroom.chatroomId;
            this.roomId = "";
            //聊天室消息---获取聊天室消息  isAllMessage: false,  allMessageSeq: 0,

          }
        }
        //聊天室---初始化
        this.initTimEvent();
        //聊天室消息---获取聊天室消息  isAllMessage: false,  allMessageSeq: 0,

        // await this.getAllMessageList()




      }
    }
  },
  async mounted() {
    //消息刷新
    this.bus
      .$off("reshMessageListEvent")
      .$on("reshMessageListEvent", (type) => {
        if (type == "resh") {
          this.isAllMessage = false;
          this.allMessageSeq = 0;
          setTimeout(() => {
            try {
              this.$refs.messageList.tobottom()

            } catch (error) {
              
            }

          }, 1000);
        }
        let id
        try {
          id = this.messageArr[0].ID || ''

        } catch (error) {
          id = ''
        }
        console.log('刷新获取全部消息');
        this.getAllMessageList(id);
      });

    // this.getAllMessageList()
  },
  methods: {
    // moment,
    showAudienceWin() {
      this.$message.success('正在创建悬浮窗')
      store.set('liveDetail', this.liveDetail)
      ipcRenderer.send('createIMView')
    },
    uni(options) {
      return new Promise((resolve, reject) => {
        axios({
          method: options.method,
          url: options.url,
          data: options.data || {},
          headers: options.header || {},
        })
          .then(response => {
            // 如果提供了 success 函数，我们在这里调用它
            if (options.success) {
              options.success(response);
            }
            resolve(response);
          })
          .catch(error => {
            // 如果提供了 fail 函数，我们在这里调用它
            if (options.fail) {
              options.fail(error);
            }
            reject(error);
          });
      });
    },
    //全部消息
    getAllMessageList(lastMessageId) {
      // console.log('获取全部消息');
      // console.log(MYIM);
      if (this.imType == 'xk') {
        return new Promise((resolve, reject) => {
          let params = {
            chatroomId: this.roomId,
            mode: 'reverse',
            seq: 'asc',
            count: 20
          }
          if (lastMessageId) {
            params.lastMessageId = lastMessageId;
          } else {
            delete params.lastMessageId
          }
          this.uni({
            method: 'POST',
            url: 'https://im.ixunke.cn' + "/message/history",
            data: params,
            header: {
              'Authorization': MYIM.TOKEN
            },
            success: (res) => {
              if (res.data && Array.isArray(res.data)) {
                this.allMessageListHasBeenloaded = (res.data.length < 20);
                let list = this.formatMessageList(res.data);
                // console.log(list, '-----list------');
                this.messageArr = [...list, ...this.messageArr];
                try {
                  this.$refs.messageList.scrollX()
                } catch (error) {

                }
                // console.log(this.messageArr);
                resolve(this.messageArr);
              }
            }
          })
        })
      } else {
        // saveChat为0时 消息不存库 也不拉历史消息，仅适用于腾讯im
        if (this.lesson.saveChat == 0) return;

        return new Promise((resolve, reject) => {
          let params = {
            seq: lastMessageId || 0,
            pageSize: 20
          }
          if (this.isLiveRoom) {
            params.roomId = this.lesson.id;
          } else {
            params.lessonId = this.lesson.id;
          }
          _http({
            method: 'GET',
            path: '/api/livechat/save_list',
            checkToken: 1,
            params: params
          }).then((res) => {
            api().hideLoading();
            if (res.errno === 0) {
              this.allMessageListHasBeenloaded = (res.data.isEmpty == 1);
              let list = this.formatMessageList(res.data);
              this.allMessageList = [...list, ...this.allMessageList];
              resolve(this.allMessageList);
            }
          })
        })
      }
    },
    //聊天室消息---获取聊天室消息  isAllMessage: false,  allMessageSeq: 0,
    getMessageList() {
      const host = store.get("host");
      var subData = {
        seq: this.allMessageSeq || 0,
        pageSize: 10,
      };
      // console.log(this.liveDetail.type);
      if (this.liveDetail.type == "lesson") {
        subData.lessonId = this.liveDetail.id;
      } else if (this.liveDetail.type == "live") {
        subData.roomId = this.liveDetail.id;
      } else {
        subData.lessonId = this.liveDetail.id;
      }
      this.loading = true;
      this.loadText = "加载中...";
      // console.log('----------/api/livechat/save_list---------',host);

      ixunkeAxios(subData, "get", "/api/livechat/save_list")
        .then((res) => {
          if (res.errno == 0) {
            if (this.allMessageSeq == 0) {
              this.messageArr = [];
            }
            this.isAllMessage = res.data.isEmpty;
            let list = this.formatMessageList(res.data);
            this.messageArr = [...list, ...this.messageArr];
            // console.log(this.messageArr, "-----messageArr-----");
            if (this.messageArr.length) {
              this.allMessageSeq = this.messageArr[0].seq;
            }
          } else {
            // console.log(res.errno);
            // console.log('----------聊天室消息获取失败！-----------------------');
            this.$error({
              centered: true,
              title: "聊天室消息获取失败！",
              content: JSON.stringify(res.errmsg),
              okText: "知道了",
            });
          }
          setTimeout(() => {
            this.loading = false;
          }, 300);
        })
        .catch((error) => {
          // console.log(error);
          // console.log('聊天室消息获取失败');
          // this.$error({
          //   centered: true,
          //   title: "聊天室消息获取失败！",
          //   content: JSON.stringify(error),
          //   okText: "知道了",
          // });
          // setTimeout(() => {
          //   this.loading = false;
          // }, 300);
        });
    },
    //聊天室消息---追加角色身份，追加大写ID作为唯一标识
    formatMessageList(list) {
      if (list.data) {
        list = list.data;
      }
      list.forEach(item => {
        if (this.imType == 'xk') {
          item.to = this.roomId;
          item.ID = item.messageid;
          item.seq = item.messageid;
          item.from = item.userid;
          item.status = 'success';
          item.payload = {
            data: item.message
          };
          item.isQuestion = item.isquestion;
          item.pMsgId = item.pmsgid || '';
          item.nick = item.nickname;
        }
        // if(this.roomManagers.length){
        // 	let manager = this.roomManagers.find(m=>{
        // 		return m.Member_Account == item.from;
        // 	})
        // 	manager = manager || {};
        // 	item.nameCard = manager.alias || manager.identity || '';
        // }else{
        item.nameCard = '';
        // }
        item.ID = item.ID || item.id || item.msgId;
      })
      return list;
    },
    //聊天室---初始化
    async initTimEvent() {
      this.SDKState = false;
      if (!this.SDKAppId) return;
      

      try {
        await MYIM.destroy()
        MYIM = null;
        // console.log('销毁im实例成功');
      } catch (error) {
        console.log('销毁im实例失败');
      }
      if (this.imType == 'xk') {
        MYIM = XKIM;
      } else {
        MYIM = TIM;
      }
      // 腾讯im初始化方法
      // this.tim = new TIM.create({
      //   SDKAppID: this.SDKAppId,
      // });
      // console.log(this.SDKAppId);
      // console.log(this.tim,'-----SDKAppID------');
      this.tim = MYIM.create({
        SDKAppID: this.SDKAppId,
        chatroomId: this.roomId
      });
      // console.log(this.tim,'----TIM----');
      window.TIM = this.tim;
      // 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
      // this.tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
      this.tim.setLogLevel(1); // release级别，SDK 输出关键信息，生产环境时建议使用
      await this.timListenerEvent();
      // 注册 COS SDK 插件
      // await this.tim.registerPlugin({ "cos-js-sdk": COS });
      await this.timLoginEvent();

    },
    //聊天室---监听事件
    timListenerEvent() {
      // // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
      this.tim.on('sdkStateReady', this.timReadyChangeEvent);
      // 收到新消息
      this.tim.on('onMessageReceived', this.onReceiveMessage);
      // this.tim.on(TIM.EVENT.MESSAGE_RECEIVED, this.timReadyChangeEvent, this);
      // this.tim.off(TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage);
    },
    //聊天室---监听事件---SDK_READY 事件
    timReadyChangeEvent({ name }) {
      if (name === 'sdkStateReady') {
        this.joinChatroomEvent();
      } else {
        this.SDKState = false;
      }
    },
    //聊天室---监听事件---收到新消息
    onReceiveMessage({ data }) {
      // 过滤非本房间消息，比如全局的系统消息
      let result = data.filter((item) => {
        return item.to === this.chatroomId;
      });
      // 本房间系统消息
      let systemMessage = result.filter((item) => {
        return item.type === "TIMGroupSystemNoticeElem";
      });
      systemMessage.forEach((item) => {
        if (item.payload.operationType == 9) {
          // 被设置为管理员  //无法区分被设置为教师还是助教 暂不处理
        } else if (item.payload.operationType == 10) {
          // 被取消管理员
        }
      });
      //聊天室---往列表中添加消息
      this.pushMessage(result);
    },
    //聊天室---登录
    async timLoginEvent() {

      if (this.loginlock) {
        return false
      }
      this.loginlock = true
      // console.log('开始登陆');
      // console.log({ userID: this.userID,
      //     userSig: this.userSig,});
      // console.log({
      //     userID: this.userID,
      //     userSig: this.userSig,
      //   });


      if (this.imType !== 'xk') {
        this.tim
          .login({
            userID: this.userID,
            userSig: this.userSig,
          })
          .then((imResponse) => {
            console.log(imResponse);

            this.joinChatroomEvent();
            // console.log(imResponse.data);
            if (imResponse.data.repeatLogin === true) {
              // console.log(imResponse.data.errorInfo)
              // console.log('标识账号已登录，本次登录操作为重复登录');
              // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
            }
            setTimeout(() => {
              this.updateMyProfile();
            }, 1200);
          })
          .catch((imError) => {
            console.log(imError);
            console.log("聊天室登录失败！");
            this.$error({
              centered: true,
              title: "聊天室登录失败！",
              content: `IM登录失败：${imError}，请稍后重试`,
              okText: "知道了",
            });
          });
      } else {
        this.tim
          .login({
            userID: this.userID,
            userSig: this.userSig,
          }).then(res => {

          })
      }

    },
    //聊天室---进入聊天室
    joinChatroomEvent(options = {}) {
      // console.log({groupID: this.chatroomId,
      // type: TIM.TYPES.GRP_CHATROOM,});
      this.tim
        .joinGroup({
          groupID: this.chatroomId,
          type: 'ChatRoom',
        })
        .then((imResponse) => {
          // console.log(this.chatroomId);
          switch (imResponse.data.status) {
            case 'WaitAdminApproval':
              // console.log('等待管理员同意中...');
              // this.$message.warning('等待管理员同意中...')
              break;
            case 'JoinedSuccess':
              // console.log('已进入聊天室！！！');
              // this.$message.success('已进入聊天室！！！')
              this.SDKState = true;
              options.success && options.success();
              break;
            case 'AlreadyInGroup':
              // console.log('已经在聊天室！！！');
              // this.$message.success('已经在聊天室！！！')
              this.SDKState = true;
              options.success && options.success();
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          console.log('llllll');
          if (error.code === 10013) {
            this.SDKState = true;
            options.success && options.success();
          } else {
            this.$error({
              centered: true,
              title: "进入聊天室失败！",
              content: `进入聊天室失败：${error.message}`,
              okText: "知道了",
            });
          }
        });
    },
    //聊天室---更新个人资料
    updateMyProfile() {
      this.tim
        .updateMyProfile({
          nick: this.nickname,
          avatar: this.avatarUrl,
        })
        .then((imResponse) => {
          // console.log('更新资料成功:', imResponse.data) // 更新资料成功
        })
        .catch((imError) => {
          console.warn("updateMyProfile error:", imError); // 更新资料失败的相关信息
        });
    },
    //聊天室---创建消息
    createMessageEvent(data) {
      let message;
      let type = data.type;
      // console.log(data,'data----');
      let messageOptions = {
        to: this.chatroomId,
        conversationType: 'GROUP',
        priority: 'High',
      };
      console.log(type,'-----type---');
      // console.log(messageOptions);
      // 1. 创建消息实例，接口返回的实例可以上屏
      switch (type) {
        case "lianmai":
          if (this.quoteObj) {
            data.quote = this.quoteObj.quote;
            data.quoter = this.quoteObj.quoter;
          }
          message = this.tim.createCustomMessage(
            Object.assign(messageOptions, {
              payload: {
                data: JSON.stringify(data),
              },
            })
          );
          message.isQuestion = data.isQuestion ? 1 : 0;
          break;
        case "text":
          if (this.quoteObj) {
            data.quote = this.quoteObj.quote;
            data.quoter = this.quoteObj.quoter;
          }
          message = this.tim.createCustomMessage(
            Object.assign(messageOptions, {
              payload: {
                data: JSON.stringify(data),
              },
            })
          );
          message.isQuestion = data.isQuestion ? 1 : 0;
          break;
        case "image":
          let file = data.image;
          message = this.tim.createImageMessage(
            Object.assign(messageOptions, {
              payload: {
                file: file,
              },
            })
          );
          break;
        case "audio":
          message = this.tim.createCustomMessage(
            Object.assign(messageOptions, {
              payload: {
                data: JSON.stringify(data.audio),
              },
            })
          );
          break;
        case "tip":
          message = this.tim.createCustomMessage(
            Object.assign(messageOptions, {
              payload: {
                data: JSON.stringify(data.tip),
              },
            })
          );
          break;
        case "product":
          message = this.tim.createCustomMessage(
            Object.assign(messageOptions, {
              payload: {
                data: JSON.stringify(data.data),
              },
            })
          );
          break;
        case "vote":
          message = this.tim.createCustomMessage(
            Object.assign(messageOptions, {
              payload: {
                data: JSON.stringify(data),
              },
            })
          );
          break;
        case "questionnaire":
          message = this.tim.createCustomMessage(
            Object.assign(messageOptions, {
              payload: {
                data: JSON.stringify(data),
              },
            })
          );
          break;
        case "gift":
          message = this.tim.createCustomMessage(
            Object.assign(messageOptions, {
              payload: {
                data: JSON.stringify(data),
              },
            })
          );
          break;
        case "fudai":
          message = this.tim.createCustomMessage(
            Object.assign(messageOptions, {
              payload: {
                data: JSON.stringify(data),
              },
            })
          );
          break;
        case "openfudai":
          message = this.tim.createCustomMessage(
            Object.assign(messageOptions, {
              payload: {
                data: JSON.stringify(data),
              },
            })
          );
          break;
      }
      message.nick = this.nickname || "匿名用户";
      message.avatar = this.avatarUrl;
      message.nameCard = "";
      message.isGroupTip = type == "tip";
      message.nameCard = this.userNameCard;
      message.isGiftMessage = type == "gift";
      message.isOpenfudaiMessage = type == "openfudai";
      message.isDianzanMessage = type == "dianzan";
      //聊天室---往列表中添加消息
      // this.pushMessage([message]);
      //发送消息
      this.sendMessageEvent(message);
    },
    //聊天室---往列表中添加消息
    pushMessage(data) {
      let types = ["TIMTextElem", "TIMImageElem", "TIMCustomElem"];
      // 过滤未知类型消息
      let result = data.filter((item) => {
        return types.includes(item.type);
      });
      if (result.length == 0) return;
      //需要循环
      for (var i = 0; i < result.length; i++) {
        let item = result[i];
        console.log(item);
        // 判断是否是群提示消息
        let groupTip = {};
        try {
          if (item.type == "TIMCustomElem") {
            let data = JSON.parse(item.payload.data);
            if (data.type == "groupTip") {
              groupTip = data;
              return
            }
            if (data.type == "update_coupon") {
              return
            }

          }
          if (item.stype == "dianzan") {
            return
          }
        } catch (e) {
          //TODO handle the exception
          groupTip = {};
        }
        if (groupTip) {
          if (groupTip.operationType == "stopLive") {
            // 禁用自己的输入区域
            this.masterHasStopLive = true;
          } else if (groupTip.operationType == "revokeMessage") {
            // 接收到管理员撤回消息到通知
            let seq = groupTip.data.seq;
            let user = groupTip.data.revoker;
            function revokeMessageList(arr = []) {
              for (let i = 0; i < arr.length; i++) {
                let itemSeq = arr[i].sequence || arr[i].seq;
                if (itemSeq == seq) {
                  arr[i].isRevoked = true;
                  arr[i]._revoker = user;
                  break;
                }
              }
              return JSON.parse(JSON.stringify(arr));
            }
            this.messageArr = revokeMessageList(this.messageArr);
            // console.log(this.messageArr);
          }
        }
        // console.log(this.allMessageList);
        if (groupTip.operationType == "revokeMessage") return;
        // console.log(item);
        // return
        let payload = JSON.parse(item.payload.data);
        if (payload.type == "lianmai") {
          console.log(payload);
          let action = payload.data.action;
          if(action == "101"){
            console.log('发送连麦消息成功');
            this.$emit('Send101Success')
          }else if (action == "201") {
            // console.log(payload.data);
            // console.log("观众接受连麦");
            // this.lianmai.state = true
          } else if (action == "202") {
            console.log("观众拒绝连麦");
          } else if (action == "203") {
            console.log(payload.data);
            console.log("观众申请连麦");
          } else if (action == "209") {
            console.log(payload.data);
            console.log("观众取消连麦");
          }
          return;
        }

        // 追加到管理员消息列表
        // if(this.imType!=='xk'){
        this.messageArr.push(item);
        this.$refs.messageList.chatContentScrollBottomEvent(1)
        // }

        // console.log(this.messageArr);


        // this.messageArr.push(item)
      }
    },
    //聊天室---发送消息
    sendMessageEvent(message) {
      const tim = this.tim;
      this.tim
        .sendMessage(message)
        .then((imResponse) => {
          // if (!message.isGroupTip) {


          this.saveMessageEvent(message);
          setTimeout(() => {
            this.$refs.messageList.tobottom()
          }, 300);
          // }
          // #ifdef H5
          let imagePicker = document.getElementById("imagePicker");
          if (imagePicker) {
            imagePicker.value = null;
          }
          // #endif
          this.sendMessageErrorCount = 0;
        })
        .catch((error) => {
          // console.log(error.code);
          this.sendMessageErrorCount = this.sendMessageErrorCount || 1;
          if (error.code == 10007 && this.sendMessageErrorCount <= 2) {
            this.sendMessageErrorCount++;
            // 无权限
            // 尝试重新加群 至多2次
            this.joinChatroomEvent({
              success: () => {
                console.log("加入群聊成功");
                this.sendMessageEvent(message);
              },
            });
            return;
          } else if (error.code == 2101) {
            console.log("未登录");
            tim.logout();
            this.initTimEvent();

            this.timLoginEvent();
            // this.timLoginEvent({
            //   success: () => {
            //     this.timListenerEvent()
            //     console.log('登录成功');
            //     setTimeout(() => {
            //       this.sendMessageEvent(message)

            //     }, 1000);
            //   },
            // })
          }
          let errMsg = error.message;
          switch (error.code) {
            case 10017:
              this.bus.$emit("chatTipEvent", {
                type: "error",
                content: "您已被禁言！",
              });
              break;
          }
        });
    },
    //聊天室---保存消息
    saveMessageEvent(msg) {
      if (JSON.parse(msg.payload.data).type == "lianmai") {
        console.log("不需要存");
        return;
      }

      let message = JSON.parse(JSON.stringify(msg));
      message.chatroomId = encodeURIComponent(this.chatroomId);
      message.msgId = message.ID;
      message.pMsgId = 0;
      message.fromRole = 1;
      message.payload = JSON.stringify(message.payload);
      // console.log(message);
      // 如果当前存在引用消息体，这里置空引用消息体
      // 最好先判断一下本次是发送的文本消息 再置空引用
      if (this.quoteObj) {
        message.pMsgId = this.quoteObj.id;
        this.quoteObj = null;
      }
      ixunkeAxios(message, "post", "/api/livechat/save_chat").then((res) => {
        // if (message.type == 'TIMImageElem') {
        //   this.isAllMessage = false
        //   this.allMessageSeq = 0
        //   this.getMessageList()
        // }
      });
    },
    //聊天室---消息处理
    messageAction(params) {
      let message = params;
      switch (params.action) {
        case "shutup":
          this.shutup(message);
          break;
        case "revoke":
          this.revokeMessage(message);
          break;
        case "quote":
          this.quoteMessage(message);
          break;
        case "copy":
          let url = params.payload.text || params.payload.imageUrl;
          let oInput = document.createElement("input");
          oInput.value = url;
          document.body.appendChild(oInput);
          oInput.select();
          document.execCommand("Copy");
          this.bus.$emit("chatTipEvent", {
            type: "success",
            content: "复制成功！",
          });
          oInput.remove();
          break;
        default:
          break;
      }
    },
    //聊天室---撤回消息
    revokeMessage(message) {
      let subData = {
        chatroomId: encodeURIComponent(this.chatroomId),
        seqList: JSON.stringify([message.sequence || message.seq]),
      };
      this.messageLoadText = "请稍候...";
      this.messageLoading = true;
      ixunkeAxios(subData, "post", "/api/livechat/group_msg_recall").then(
        (res) => {
          if (res.errno == 0) {
            this.bus.$emit("chatTipEvent", {
              type: "success",
              content: "消息撤回成功！",
            });
            this.createGroupTipMessage("revokeMessage", message);
          } else {
            this.bus.$emit("chatTipEvent", {
              type: "error",
              content: "消息撤回失败！",
            });
          }
          setTimeout(() => {
            this.messageLoading = false;
          }, 300);
        }
      );
    },
    ///聊天室---引用消息
    quoteMessage(message) {
      this.quoteObj = {
        quote: message.payload.text,
        quoter: message.nick,
        id: message.ID,
      };
    },
    ///聊天室---取消引用
    clearQuoteObj() {
      this.quoteObj = null;
    },
    //聊天室---管理员禁言用户
    shutup(message) {
      this.tim
        .setGroupMemberMuteTime({
          groupID: this.chatroomId,
          userID: message.from,
          muteTime: message.muteTime * 1,
        })
        .then((imResponse) => {
          if (message.muteTime) {
            this.bus.$emit("chatTipEvent", {
              type: "success",
              content: "禁言成功！",
            });
          } else {
            this.bus.$emit("chatTipEvent", {
              type: "success",
              content: "已取消禁言！",
            });
          }
        })
        .catch((imError) => {
          console.log(imError); // 禁言失败的相关信息
          this.bus.$emit("chatTipEvent", {
            type: "error",
            content: "用户可能已下线！",
          });
        });
    },
    //聊天室---创建群提示消息
    createGroupTipMessage(operationType, options = {}) {
      let text = "";
      let data = null;
      switch (operationType) {
        case "joinGroup":
          text = `${this.$store.getters.userInfo.nickname ||
            this.$store.getters.userInfo.username ||
            "匿名用户"
            }进入了直播间`;
          break;
        case "quitGroup":
          text = `${this.$store.getters.userInfo.nickname ||
            this.$store.getters.userInfo.username ||
            "匿名用户"
            }离开了直播间`;
          break;
        case "stopLive":
          text = `直播已结束，互动环节关闭`;
          break;
        case "revokeMessage":
          data = {
            revoker:
              options.from == this.userID
                ? ""
                : this.$store.getters.userInfo.nickname ||
                this.$store.getters.userInfo.username ||
                "匿名用户",
            seq: options.seq || options.sequence,
          };
          break;
        default:
          break;
      }
      let tipData = {
        type: "groupTip",
        operationType: operationType,
        text: text,
      };
      if (data) {
        tipData.data = data;
      }
      this.createMessageEvent({
        type: "tip",
        tip: tipData,
      });
    },
    //聊天室---退出聊天室成功
    quitChatroomEvent() {
      if (this.SDKState && this.tim) {
        this.tim.off('sdkStateReady', this.timReadyChangeEvent);
        this.tim.off('onMessageReceived', this.onReceiveMessage);
        this.tim
          .logout()
          .then((imResponse) => {
            this.$message.success("已成功退出聊天室！！！");
          })
          .catch((imError) => {
            this.$error({
              centered: true,
              title: "退出聊天室失败！",
              content: `退出聊天室失败：${imError}`,
              okText: "知道了",
            });
          });
      }
    },
    //聊天室---选择消息类型
    tabBtnClickEvent(type) {
      this.tabBtn = type;
    },
  },
  beforeDestroy() {
    // console.log('销毁messageArr-------------------------');
    this.messageArr = [];
    this.allMessageList = [];
    try {
      MYIM.destroy()
      console.log('销毁im实例成功');
    } catch (error) {
      console.log('销毁im实例失败');
    }
  },
};
</script>
